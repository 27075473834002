<template>
  <v-container fluid>
    <v-row justify="start" align="center" no-gutters>

      <template v-for="contact of items">
        <v-col cols="12" lg="4" :key="contact.id"  class="ma-1">
          <v-card>
          <v-row class="bg-main" style="min-height: 100px;" no-gutters>

            <v-col cols="12" d-flex>
              <v-row no-gutters justify="end">
                <v-col cols="10" lg="11">
                  <p>{{ contact.person.position }}</p>
                </v-col>
                <v-col cols="2" lg="1">
                  <v-menu
                      transition="scale-transition"
                      origin="center center"
                      min-width="320px"
                      offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          v-on="on"
                          icon>
                        <v-icon>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <!-- Edit -->
                      <v-list-item @click="toggleAddOrEditDialog(true, contact)">
                        <v-list-item-icon>
                          <v-icon>fas fa-pencil-alt</v-icon>
                        </v-list-item-icon>
                        <span>{{ $t('common.buttons.edit') }}</span>
                      </v-list-item>

                      <!-- Delete -->
                      <v-list-item @click="submitDelete(contact)">
                        <v-list-item-icon>
                          <v-icon>fas fa-trash-alt</v-icon>
                        </v-list-item-icon>
                        <span>{{ $t('common.buttons.delete') }}</span>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" style="min-height: 60px;">
              <p>{{ contact.person.firstname | truncate }} {{ contact.person.name | truncate }}</p>
              <p v-if="!!contact.person.email">{{ contact.person.email }}</p>
              <p v-if="!!contact.person.phone">{{ contact.person.phone }}</p>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
      </template>

      <v-hover>
        <v-col
            cols="12"
            lg="4"
            :class="`bg-main pointer ma-1 elevation-${hover ? 12 : 2}`"
            slot-scope="{hover}">
          <v-row
              align="center"
              justify="center"
              @click="toggleAddOrEditDialog(true, null)"
              style="min-height: 100px;">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-icon x-large class="text-center mt-2 d-block" flat>fas fa-plus-circle</v-icon>
                </v-col>
                <v-col cols="12" class="mt-1">
                  <p class="text-center">{{$t('common.buttons.add')}}</p>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-col>
      </v-hover>
    </v-row>

    <!-- Dialog -->
    <ContactDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :item="currentItem"
      :base_url_api_add="base_url_api_add"
      :base_url_api_edit="base_url_api_edit"
      @close="(needRefresh) => toggleAddOrEditDialog(false, null, needRefresh)"
    />

  </v-container>
</template>

<script>
import formatter from '@/mixins/formatter'

export default {
  name: "ContactDisplay",

  mixins: [formatter],

  props: {
    items: { type: Array, default: () => [] },
    base_url_api_edit: { type: String, default: null },
    base_url_api_add: { type: String, default: null },
    base_url_api_delete: { type: String, default: null }
  },

  data() {
    return {
      currentItem: null,
      showDialog: {
        addOrEdit: false
      }
    }
  },

  components: {
    ContactDialog: () => import("@/components/Common/ContactDialog")

  },

  methods: {
    toggleAddOrEditDialog(val, item = null, needRefresh = false) {
      console.log(item);
      this.currentItem = item;
      this.showDialog.addOrEdit = val;

      if (needRefresh)
        // => Store ?
        this.$emit('refresh');
    },

    submitDelete(item) {
      this.$http.delete(`${this.base_url_api_delete}/${item.id}`,{
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.person.dialog.delete.success'));
            this.$emit('refresh');
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            //this.isLoadingBtn.btnSave = false;
          });
    },

  }
}
</script>

<style scoped>

</style>
